.list-item {
  background: #fff;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0 20px 45px -28px $primary;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;

  &.featured {
    border-left: 5px solid $primary;
  }

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 767px) {
    padding: 50px 20px 20px 20px;
    margin-bottom: 60px;
  }
}

.list-item__left {
  display: flex;
  align-items: center;

  @media (max-width: 1199px) {
    flex: 1.5;
  }

  @media (max-width: 991px) {
    width: 100%;
    position: relative;
    padding-bottom: 20px;
    flex: 1;

    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 1px;
      bottom: 0;
      left: 0;
      background: #ccc;
    }
  }
}

.list-item__right {
  @media (max-width: 1199px) {
    flex: 1;
  }

  @media (max-width: 991px) {
    padding-top: 20px;
  }
}

.brand-logo {
  margin-right: 25px;

  @media (max-width: 767px) {
    margin-right: 0;
    position: absolute;
    top: -80px;
    left: 0;
  }

  img {
    width: 88px;

    @media (max-width: 1199px) {
      width: 75px;
    }

    @media (max-width: 767px) {
      width: 60px;
    }
  }
}

.job-details-row {
  display: flex;
  align-items: center;
}

.brand-name {
  color: $primary;
  font-weight: bold;
  margin-right: 10px;

  @media (max-width: 767px) {
    font-size: 14px;
  }
}

.pill {
  padding: 10px 12px 5px 12px;
  border-radius: 30px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1;
  margin-right: 8px;

  @media (max-width: 1199px) {
    padding: 7px 12px 5px 12px;
    font-size: 10px;
  }

  &.featured {
    background: $very-dark-cyan;
    color: #fff;
  }

  &.new {
    background: $primary;
    color: #fff;
  }
}

.position {
  font-weight: 700;
  color: #313737;
  font-size: 18px;
  margin: 10px 0;

  @media (max-width: 767px) {
    font-size: 16px;
  }
}

.job-subinfo {
  color: $dark-cyan;
  font-size: 15px;

  @media (max-width: 1199px) {
    font-size: 14px;
  }

  @media (max-width: 767px) {
    font-size: 13px;
  }
}

.job-details-dot {
  background: $dark-cyan;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  margin: 0 15px;

  @media (max-width: 767px) {
    margin: 0 10px;
  }
}

.job-tags {
  button {
    background: $light-cyan;
    color: $primary;
    font-weight: 700;
    border: 0;
    font-size: 13px;
    padding: 8px 10px 5px 10px;
    border-radius: 4px;
    margin: 0 8px;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      background: $primary;
      color: #fff;
    }

    &:focus {
      outline: 0;
    }

    @media (max-width: 1199px) {
      margin: 8px;
    }
  }
}
