.list-wrapper {
  background: $light-cyan;
  flex-grow: 1;
  padding: 90px 120px;

  @media (max-width: 1199px) {
    padding: 90px 60px;
  }

  @media (max-width: 991px) {
    padding: 90px 30px;
  }

  @media (max-width: 991px) {
    padding: 90px 20px;
  }
}

.list-container {
  margin: 0 auto;
  position: relative;
  max-width: 1400px;
}

.filter-wrapper {
  background: #fff;
  padding: 15px 25px;
  display: flex;
  align-items: center;
  position: relative;
  margin-top: -130px;
  margin-bottom: 40px;
  border-radius: 5px;
  box-shadow: 0 20px 45px -28px $primary;
  transition: all 0.3s;
  opacity: 1;

  @media (max-width: 767px) {
    padding: 15px 15px;
    margin-bottom: 70px;
  }
}

.filters {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
}

.filter {
  color: $primary;
  font-size: 13px;
  border-radius: 5px;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin: 8px;
}

.filter-text {
  background: $light-cyan;
  padding: 10px;
  @media (max-width: 1199px) {
    padding: 8px;
  }
}

.filter-remove {
  height: 34px;
  width: 34px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  border: 0;
  border-radius: 0 5px 5px 0;
  background: $primary;
  transition: all 0.3s;
  cursor: pointer;

  @media (max-width: 1199px) {
    height: 30px;
    width: 30px;
  }

  &:hover {
    background: $very-dark-cyan;
  }
}

.clear-filters {
  cursor: pointer;
  font-size: 13px;
  color: $primary;
  font-weight: bold;
  border: 0;
  background: transparent;
  &:hover {
    border-bottom: 1px solid $primary;
  }
}
