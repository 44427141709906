.header-wrapper {
  background: $primary;
  img {
    width: 100%;
  }
}

.header-desktop {
  display: none;
}

.header-mobile {
  display: block;
}

@media (min-width: 768px) {
  .header-desktop {
    display: block;
  }

  .header-mobile {
    display: none;
  }
}
